<script setup>
  import { ref } from 'vue';
 import {useFiltersStore} from "../../stores/filters";
 import {useRouter} from "vue-router";
 import { appendLanguagePrefixURL } from "@/mixins/appendLanguageURL";

 const router = useRouter();
 const props = defineProps({
   pageModifier: 'default',
   crumb: Array
 });
 const webshopSlug = ref(webshopSlugRef);
 const ourProductsLabel = ref(ourAssortmentTextCode);

 const goBack = () => {
   if (props.crumb === undefined) {
     // Vue-router
     let location = '';

     if (useFiltersStore().mainItem.breadcrumb[useFiltersStore().mainItem.breadcrumb.length - 2]) {
       location = useFiltersStore().mainItem.breadcrumb[useFiltersStore().mainItem.breadcrumb.length - 2]
       console.log(location.url)
       router.push(location.url);
     } else {
       window.location = webshopSlug.value;
     }
   } else {
     // window-location
     if (props.crumb[props.crumb.length -1]) {
       window.location.href = props.crumb[props.crumb.length - 1].url;
     } else {
       window.location.href = '/'
     }
   }
 }
</script>

<template>
<!--  TODO: aria-label="{get_text code="BREADCRUMB_ARIA"}" in nav element-->
<!--  TODO: not hardcode name etc-->
  <nav :class="'breadcrumbs breadcrumbs--' + pageModifier" >
  <ul class="breadcrumbs__list">
    <li class="breadcrumbs__item breadcrumbs__item--back">
      <button data-event="back" @click="goBack">Terug</button>
    </li>
    <!-- <li class="breadcrumbs__item">
      <a :href="appendLanguagePrefixURL('/')">Home</a>
    </li> -->
    <li class="breadcrumbs__item">
      <a :href="appendLanguagePrefixURL(webshopSlug)">{{ourProductsLabel}}</a>
    </li>
    <li v-if="crumb === undefined && useFiltersStore().mainItem" class="breadcrumbs__item" v-for="item in useFiltersStore().mainItem.breadcrumb">
      <router-link :to="item.url">{{item.label}}</router-link>
    </li>
    <li v-else class="breadcrumbs__item" v-for="item in crumb">
      <a :href="item.url">{{item.label}}</a>
    </li>
  </ul>
  </nav>
</template>

<style lang="scss">
  main:has(.products__empty),
  main:has(.products--search) {
    .breadcrumbs {
      @apply hidden;
    }
  }
</style>
